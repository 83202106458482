import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportingComponent } from './pages/reporting/reporting.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { ReportAddComponent } from './components/report-add/report-add.component';
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";

import {MaterialModule} from './material-module'

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/it';
import { ReportHistoryComponent } from './components/report-history/report-history.component';
import { ReportingWeekComponent } from './pages/reporting-week/reporting-week.component';
import { ReportingWeekAdminComponent } from './components/reporting-week-admin/reporting-week-admin.component';
import { ReportChartComponent } from './components/report-chart/report-chart.component';
import { ReportChartAdminComponent } from './components/report-chart-admin/report-chart-admin.component';
registerLocaleData(localePy, 'it');

@NgModule({
    declarations: [
    AppComponent,
    LoginComponent,
    ReportingComponent,
    ReportListComponent,
    ReportAddComponent,
    AutocompleteComponent,
    ReportHistoryComponent,
    ReportingWeekComponent,
    ReportingWeekAdminComponent,
    ReportChartComponent,
    ReportChartAdminComponent
    ],
    imports: [MaterialModule],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }],
    bootstrap: [AppComponent]
})

export class AppModule { }
