import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { AddReportParam, TagDto, TagTypology } from '../../models/reportDto';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-add',
  templateUrl: './report-add.component.html',
  styleUrls: ['./report-add.component.css']
})

export class ReportAddComponent implements OnChanges {
  today: Date = new Date();
  project: TagDto[]= []
  activity: TagDto[] = []
  brTag: any[] = [];
  error: { active: boolean, message: string } = { active: false, message: '' };
  errorProject: number = 0;
  errorActivity: number = 0;
  resetProject: number = 0;
  resetActivity: number = 0;

  @Input() tagList: TagDto[] = [];
  @Input() datetoview: Date = new Date();
  @Output() newAdd: EventEmitter<any> = new EventEmitter();

  constructor(private reportService: ReportService) { }

  ngOnChanges(): void {
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0,0,0,0)
    this.filterProject(this.tagList)
  }

  /*
  * submit form, se quello che è required esiste
  * passo ad un controllo custom su data e ore
  * se tutto ok passo ad AddReport
  */
  onSubmit(f: NgForm) {
    if (f.valid) {
      var resp = f.value;
      var valid = this.checkValidateForm(f);
      if (valid) {
        this.AddReport(resp)
        f.controls['hours'].reset();
        f.controls['note'].reset();
        this.brTag = [];
        this.resetProject++;
        this.resetActivity++;
        this.errorProject = 0;
        this.errorActivity = 0;
      }
    }
  }

  /*
  * dopo l'emit dei tag arrivo qua e creo
  * un set per avere gli id dei tag con cui creare la BR
  */
  getTag(data: any[]) {
    data.forEach(d => {
      if (d.type == TagTypology.Project)
        this.brTag = this.brTag.filter(b => b.type != TagTypology.Project)

      this.brTag.push({ id: d.id, type: d.type })
    })
  }

  /*
  * rimuovo un tag rimosso dopo l'emit
  * emitRemoveTag
  */
  removeTag(id: number) {
    this.brTag = this.brTag.filter(t => t.id != id)
  }

  /*
  * controllo che le ore segnate siano interi o con decimale 5
  * controllo che la data sia uguale o inferiore a quella di oggi: ora non usato
  * controllo che ci sia il progetto deve essere uno ed è obbligatorio
  * controllo che ci sia un attività deve essere uno ed è obbligatorio
  * torna un booleano
  */
  private checkValidateForm(f: NgForm) {
    var form = f.value;
    var _correctH = true;
    var _correctP = true;

    var project = this.brTag.filter(t => t.type == TagTypology.Project);
    if (project.length == 0) {
      this.errorProject++;
      _correctP = false;
    }

    var activity = this.brTag.filter(t => t.type == TagTypology.Activity);
    if (activity.length == 0) {
      this.errorActivity++;
      _correctP = false;
    }

    if (!form.hours) {
      f.controls['hours'].setErrors({ 'incorrect': true });
      _correctH = false;
    }
    else {
      var decimals = form.hours - Math.floor(form.hours);
      decimals.toFixed(1);
      if (!(decimals.toString() == '0.5' || decimals.toString() == '0')) {
        f.controls['hours'].setErrors({ 'incorrect': true });
        _correctH = false;
      }
    }

    return (_correctH && _correctP) ? true : false; 

    //var _date = new Date(form.dateActivity);
    //_date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0,0,0,0);
    //var diff = new Date(_date.getTime() - this.today.getTime());
    //if (diff.getTime() > 0) {
    //  f.controls['dateActivity'].setErrors({ 'incorrect': true });
    //  return false
    //}
     
  }

  /*
  * effettuo la chiamata Add, come parametro mi arriva il form
  * senza le select che sono in this.brTag
  */
  private AddReport(model: any) {
    let param = new AddReportParam();
    let _dateActivity = new Date(Date.UTC(model.dateActivity.getFullYear(), model.dateActivity.getMonth(), model.dateActivity.getDate(), 0, 0, 0, 0))
    param.dateActivity = _dateActivity;
    param.hours = model.hours;
    param.note = model.note;

    //prendo solo l'id
    var brId = this.brTag.map(function (i) {
      return i.id;
    });
    brId = [...new Set(brId)]
    param.reportBR = brId;

    this.reportService.AddReport(param).subscribe(resp => {
      if (resp.ok) {
        this.newAdd.emit();
      } else {
        this.error = { active: true, message: "aggiunta report fallita:" + resp.errorMessage }
      }
    })
  }

  /*
  * filtro la lista completa dei tag
  * per avere due set divisi tra progetti e attività
  * da passare alle due select
  */
  private filterProject(list: TagDto[]) {
    this.project = list.filter(t => t.typology == TagTypology.Project && t.status == true)
    this.activity = list.filter(t => t.typology == TagTypology.Activity && t.status == true)
  }

}
