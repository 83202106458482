import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { getReportHistory } from '../../models/getReportParam';
import { ReportBaseDto } from '../../models/reportDto';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.css']
})
export class ReportHistoryComponent implements OnChanges {
  allDate: Date[] = [];
  calendar: ReportBaseDto[] = [];
  today: number = 0;
  datetoview: Date = new Date();

  @Input() historyReload:any
  @Output() setDate: EventEmitter<Date> = new EventEmitter();

  constructor(private reportService: ReportService) { }

  ngOnChanges()
  {
    if (this.historyReload) {
      var _today = new Date();
      this.today = new Date(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0, 0, 0).getTime();
      this.allDate = [];
      this.calendar = [];
      this.getAllDate();
      this.getHistory();
    }
  }

  /*
  * chiamata per avere gli ultimi 7 giorni con le ore compilate
  */
  private getHistory() {
    var param = new getReportHistory();
    var _date = this.datetoview;
    param.dateActivity = new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0, 0, 0, 0));

    this.reportService.GetHistory(param).subscribe(resp => {
      if (resp.ok) {
        console.log(resp.data)
        this.matchDate(resp.data);
      }
    })
  }

  /*
  * mi creo l'oggetto con tutte le date degli ultimi 7 gg
  */
  private getAllDate() {
    var startDate = new Date(this.datetoview.getFullYear(), this.datetoview.getMonth(), this.datetoview.getDate() -4, 0, 0, 0, 0);
    var endDate;

    var count = 0, noOfDaysToAdd = 7;

    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      this.allDate.push(endDate);
      count++;
    }

  }

  /*
  * match delle date con le ore
  */
  private matchDate(data: [ReportBaseDto]) {
    var _allDate = this.allDate.map(a => a.getTime());

    this.allDate.forEach(d => {
      this.calendar.push({ dateActivity: d, hours: 0 })
    })

    data.forEach(d => {
      if (_allDate.includes(new Date(d.dateActivity).getTime())) {
        this.calendar.forEach(c => {
          c.dateActivityNumber = c.dateActivity.getTime();
          if (c.dateActivity.getTime() == new Date(d.dateActivity).getTime()) {
            c.hours = c.hours + d.hours;
          }
        })
      }
    })
  }

  /*
  * rendo cliccabile la data e mi aggiorna la schermata con la data scelta
  */
  goToDate(_date: Date) {
    this.datetoview = new Date(Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0, 0, 0, 0))
    this.setDate.emit(this.datetoview)
  }

  prevDate() {
    this.datetoview = new Date(Date.UTC(this.datetoview.getFullYear(), this.datetoview.getMonth(), this.datetoview.getDate() - 1, 0, 0, 0, 0));
    this.setDate.emit(this.datetoview)

  }

  nextDate() {
    this.datetoview = new Date(Date.UTC(this.datetoview.getFullYear(), this.datetoview.getMonth(), this.datetoview.getDate() + 1, 0, 0, 0, 0));
    this.setDate.emit(this.datetoview)

  }

}
