export class getReportParam {
  dateActivity?: Date;
}

export class getReportHistory {
  dateActivity!: Date;
  dateActivityEnd?: Date;
}

export class getReportDetailWeek {
  dateActivity!: Date;
  dateActivityEnd?: Date;
  guids: string[] = [];
  projects: number[] = [];
}
