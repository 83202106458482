import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TagDto } from '../../models/reportDto';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})

export class ReportingComponent implements OnInit {
  yesterday?: Date;
  tomorrow?: Date;
  datetoview: Date = new Date();
  today: Date = new Date();
  addInProgress: boolean = false;
  tagList: TagDto[] = [];
  user: string = "";
  welcome: string = ""
  totalHour: number = 0;
  historyReload: number = 0;
  constructor(private reportService: ReportService) { }

  ngOnInit(): void {
    this.userDetail();
    this.getCompleteTagList();
    this.setDates();
  }

  /*
  * recuperiamo la lista di tutti i tag
  * hanno 2 categorie Progetti e Attività
  */
  getCompleteTagList(): void {
    this.reportService.TagCompleteList().subscribe(res => {
      if (res.ok) {
        this.tagList = res.data;
      }
    })
  }

  /*
  * setto le nuove date dopo il click sulla data di ieri o di domani
  */
  SetDateToView(date: any) {
    this.datetoview = date;
    this.yesterday = new Date(this.GetYestOrTom('yesterday', this.datetoview));
    this.tomorrow = new Date(this.GetYestOrTom('tomorrow', this.datetoview));
  }

  /*
  * recupero il nome utente da usare
  * setto il saluto in base all'orario
  */
  private userDetail() {
    var name = localStorage.getItem("username")
    this.user = name ? name : "";

    var now = new Date().getHours();
    this.welcome = now < 13 ? "Buongiorno" : now < 17 ? "Buon pomeriggio" : "Buonasera";
  }

  /*
  * setto le varie date,
  * datetoview: quella che va mostrata, inizialmente la giornata attuale
  * yesterday e tomorrow fanno riferimento a datetoview
  */
  private setDates() {
    this.datetoview = new Date(Date.UTC(this.datetoview.getFullYear(), this.datetoview.getMonth(), this.datetoview.getDate(), 0, 0, 0, 0))
    this.yesterday = new Date(this.GetYestOrTom('yesterday', this.datetoview));
    this.tomorrow = new Date(this.GetYestOrTom('tomorrow', this.datetoview));
  }

  /*
  * setto le date di ieri e di domani
  * rispetto alla datetoview
  */
  private GetYestOrTom(type: string, datetoview: Date) {
    var _d = JSON.parse(JSON.stringify(datetoview));
    _d = new Date(_d);
    if (type == 'yesterday') {
      var _y = _d.setDate(_d.getDate() - 1);
      return _y;
    } else {
      var _t = _d.setDate(_d.getDate() + 1);
      return _t;
    }
  }

}
