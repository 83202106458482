import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Series } from 'highcharts';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
@Component({
  selector: 'app-report-chart',
  templateUrl: './report-chart.component.html',
  styleUrls: ['./report-chart.component.css']
})
export class ReportChartComponent implements OnChanges{

  @Input() data: any;
  @Output() filterProject = new EventEmitter<any>();
  DataSource: any

  ngOnChanges(){

    var activities: any[] = [];
    var activitiToChart : any[] = [];
    activities = this.data.activity.filter((res:any) =>
      res.father == true
    );
    activitiToChart = activities.map((res) => {
      return {
        name: res.project,
        y: res.total,
        id: res.idProject
      };
    });
    activitiToChart.sort((a, b) => b.y - a.y);//metto in ordine le y dal piu grnade al piu piccolo
    this.createChart(activitiToChart)


  }

  private createChart(dati: any): void {

    const chart = Highcharts.chart('chart', {

      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 600,
        marginTop: 100,
        marginBottom: 50
      },
      title: {
        text: 'Distribuzione per progetti',
        align: 'center',
    },

      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}'
      },
      accessibility: {
        point: {
            valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} % ({point.y}h)',

          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign:'middle',
        labelFormat: '<b>{name}</b>: {percentage:.1f}%',
    },
    series: [{
      name:'ore',
      showInLegend: true,
      data:dati,
      events: {
        click: (event: any) => {
          var pointid= [event.point.id]

          this.sendPointNameToParent(pointid);
        }
      }
    }]

    } as any);

  }

  sendPointNameToParent(pointid: any[]) {
    this.filterProject.emit(pointid);
  }
}

