import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { ReportingComponent } from './pages/reporting/reporting.component';
import { ReportingWeekComponent } from './pages/reporting-week/reporting-week.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reporting', component: ReportingComponent },
  { path: 'reporting-week', component: ReportingWeekComponent },
  { path: '**', pathMatch: 'full', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
