import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationDto } from '../../models/authenticationDto'
import { AccountService } from '../../services/account.service';
// @ts-ignore
import Typewriter from 't-writer.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error?: boolean = false;
  showPw: boolean = false;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.createLogo();
  }

  /*
  * submit del form per la login
  */ 
  onSubmit(f: NgForm) {
    if (!f.valid)
      this.error = true;
    else {
      var resp = f.value;
      var authenticationDto = new AuthenticationDto();
      authenticationDto.userName = resp.username;
      authenticationDto.password = resp.password;

      this.accountService.Login(authenticationDto).subscribe(resp => {
        this.error = resp.error;
        if (!resp.error) {
          localStorage.setItem("JWT", resp.token);
          localStorage.setItem("username", resp.firstName);
          localStorage.setItem("role", resp.role);
          window.open("/reporting", "_self");
        }
      })
    }

  }

  /*
  * crea l'animazione per il logo
  * usa la libreria t-writer
  */ 
  private createLogo() {
    const target = document.querySelector('.tw')

    const writer = new Typewriter(target, {
      loop: false,
      typeSpeedMin: 65,
      typeSpeedMax: 115,
      typeColor: '#fff',
      typeClass: 'fw-bold'
    })

    writer
      .type('utation.')
      .rest(700)
      .changeOps({ deleteSpeed: 50 })
      .remove(8)
      .type('ort.')
      .rest(700)
      .changeOps({ deleteSpeed: 50 })
      .remove(4)
      .type('.')
      .removeCursor()
      .start()
  }

}
