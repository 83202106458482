<div class="row">

  <div class="col-8" *ngIf="DataSource.activity.length != 0">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link tab-report-chart" id="tabel-admin-tab" [ngClass]="{'active' : reportTab == 1}" (click)="reportTab=1" data-bs-toggle="tab" data-bs-target="#tabel-admin" type="button" role="tab" aria-controls="tabel-admin" aria-selected="true">Report</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link tab-report-chart" id="chart-admin-tab" [ngClass]="{'show active' : reportTab == 2}" (click)="reportTab=2" data-bs-toggle="tab" data-bs-target="#chart-admin" type="button" role="tab" aria-controls="chart-admin" aria-selected="false">Grafici</button>
      </li>
    </ul>
  </div>
</div>

<div class="tab-content" id="myTabContent">
  <div  class="col-12 scroll-calendar  p-0 tab-pane fade" [ngClass]="{'show active' : reportTab == 1}" id="tabel-admin" style="border-radius: 6px;">
    <table *ngIf="DataSource && DataSource.activity.length > 0 && !loading" class="w-100 table_week timerep_table mb-4">
      <tr class="head">
        <td class="sticky_col p-0">
          <table class="p-0" style="border-radius: 0; width: 400px">
            <tr>
              <td class="empty sticky_col" style="width:310px"></td>
              <td class="empty text-center pe-3">totale</td>
            </tr>
          </table>
        </td>
        <td *ngFor="let m of DataSource.date" class="text-center">{{m | date: 'ccc dd/MM':'':'it'}}</td>
      </tr>

      <tr *ngFor="let a of DataSource.activity; index as i" [ngClass]="{'stripped_row' : (i % 2 == 0)}">
        <td class="sticky_col p-0">
          <table class="inner-table p-0" style="border-radius: 0; width: 400px">
            <tr>
              <td class="body" style="width: 300px" [ngClass]="{'ps-5': !a.father, 'bg-blue': a.father }">{{a.project}} <span *ngIf="!a.father">- {{ (a.activity !=null) ? a.activity : "Nessuna attività"}}</span><span *ngIf="a.user"> - {{a.user}}</span></td>
              <td *ngIf="a.total" style="width: 90px; border-radius: 0" class="text-center text-white p-0" [ngClass]="{'bg-alter': a.father, 'bg-alter-alpha': !a.father }"><span class="fw-bold">{{a.total}}</span></td>
            </tr>
          </table>
        </td>
        <td [attr.colspan]="DataSource.date.length" *ngIf="a.father" class=""></td>
        <td *ngFor="let h of a.hours" class="text-center"><span *ngIf="h > 0" class="fw-bold">{{h}}h</span> <span *ngIf="h==0">-</span></td>
      </tr>


    </table>
  </div>

  <div *ngIf="DataSource && DataSource.activity.length > 0 && !loading" class="col-12  p-0 tab-pane fade" id="chart-admin" [ngClass]="{'show active' : reportTab == 2}" style="border-radius: 6px;">
    <app-report-chart-admin  [data]="DataSource" (filterProject)=getfilterProjectByname($event)></app-report-chart-admin>
  </div>

  <p *ngIf="DataSource && DataSource.activity.length == 0" class="lead noActivity text-center">Nessuna attività trovata con i filtri selezionati.</p>
</div>
