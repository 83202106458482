import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { TagDto } from 'src/app/models/reportDto';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})

export class AutocompleteComponent implements OnChanges {
  @Input() project: TagDto[] = [];
  @Input() data: TagDto[] = [];
  @Input() type: string = "";
  @Input() error: number = 0;
  @Input() reset: number = 0;
  @Output() emitTag: EventEmitter<any> = new EventEmitter();
  @Output() emitRemoveTag: EventEmitter<any> = new EventEmitter();

  myControlProject = new FormControl('');
  selectedTag: any[] = []
  filteredOptionsProject!: Observable<TagDto[]>;

  ngOnChanges() {
    this.filteredOptionsProject = this.myControlProject.valueChanges.pipe(
      startWith(''),
      map(value => this.filterProject(value || '')),
    );


    if (this.reset) {
      this.myControlProject.reset()
      this.selectedTag = []
    }

    if (this.error)
      this.setError();
     
  }

  /*
  * dopo la selezione sulla select
  * emitto il tag che passo al componente padre
  */ 
  selected(value: any, type: string) {
    this.selectedTag = []
    var tag = { id: value.id, name: value.name, type: value.typology }
    this.selectedTag.push(tag)
    this.myControlProject.reset()
    this.emitTag.emit(this.selectedTag)
  }

  /*
  * dopo il click sulla X rimuovo il tag ed emitto per rimuoverlo anche
  * nel componente padre
  */ 
  removeTag(id: number) {
    this.selectedTag = this.selectedTag.filter(t => t.id != id);
    this.emitRemoveTag.emit(id)
  }

  /*
  * se dal componente padre mi arriva l'errore perche il progetto non è stato inserito
  * lo setto qua in modo che il bordo diventi rosso
  */ 
  private setError() {
    this.myControlProject.setErrors({ 'incorrect': true });
  }

  /*
  * filtra i risultati dell'autocomplete
  */ 
  private filterProject(value: any): TagDto[] {
    const filterValue = typeof value == "string" ? value.toLowerCase() : value.name.toLowerCase();
    return this.data.filter(option => option.name.toLowerCase().includes(filterValue));
  }

}


