
<mat-form-field class="w-100" appearance="outline">
  <input type="text" [ngClass]="selectedTag.length ? 'd-none' : ''"
         ngModel
         name="{{type}}"
         matInput
         placeholder="{{type}}"
         [formControl]="myControlProject"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)='selected($event.option.value, type)'>
    <mat-option *ngFor="let option of filteredOptionsProject | async" [value]="option">
      <span>{{option.name}}</span>
    </mat-option>
  </mat-autocomplete>
  <span *ngFor="let tag of selectedTag" class="chips me-2" (click)="removeTag(tag.id)">{{tag.name}} <i class="fa fa-close ms-2"></i></span>
</mat-form-field>

