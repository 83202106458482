<form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
  <div class="col-12 mt-4">
    <p class="lead fw-bold">Aggiungi un nuovo task</p>
  </div>

  <div class="row add_row mt-4">
    <!-- project -->
    <div class="col py-4" style="min-width: 20%; max-width: 20%;">
      <label>Progetto*</label>
      <app-autocomplete [data]="project" [type]="'Progetto'" [error]="errorProject" [reset]="resetProject" (emitTag)="getTag($event)" (emitRemoveTag)="removeTag($event)" />
    </div>
    <!-- activity -->
    <div class="col py-4" style="min-width: 31%; max-width: 31%;">
      <label>Attività*</label>
      <app-autocomplete [data]="activity" [type]="'Attività'" [error]="errorActivity" (emitTag)="getTag($event)" [reset]="resetActivity" (emitRemoveTag)="removeTag($event)" />
    </div>

    <!-- Note -->
    <div class="col py-4">
      <label>Note</label>
      <mat-form-field appearance="outline" class="w-100">
        <input name="note" ngModel matInput type="text" maxlength="500" placeholder="Note" autocomplete="off" />
      </mat-form-field>
    </div>
    <!-- Ore -->
    <div class="col py-4"  style="min-width: 10%; max-width: 10%">
      <label style="white-space: nowrap">Ore* <span class="fw-normal">(0.5 = 30min)</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input matInput name="hours" ngModel type="number" step="0.5" min="0" max="8" placeholder="Ore" />
      </mat-form-field>
    </div>

    <!-- Date Activity -->
    <div class="col-4 py-4 d-none">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Data</mat-label>
        <input matInput name="dateActivity" ngModel [matDatepicker]="myDatepicker" [(ngModel)]="datetoview" required />
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col" style="min-width: 8%; max-width: 8%">
      <button class="btn-save mb-4 w-100">
        <i class="fa fa-plus" style="font-size: 1.2rem;"></i>
      </button>
    </div>
  </div>
</form>

<p class="lead" *ngIf="error.active">{{error.message}}</p>
<!--<span class="loader"></span>-->
