<div class="container my-4">
  <div class="row">

    <div class="row mb-4">
      <div class="col-12 text-center">
        <p><span class="lead fw-bold">{{today | date: 'dd MMMM YYYY':'':'it'}}</span></p>
        <h2 class="m-0">{{welcome}} {{user}}</h2>
      </div>
    </div>

    <div [formGroup]="form" class="row align-items-center py-4">
      <div class="col-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Seleziona Periodo</mat-label>
          <mat-date-range-input formGroupName="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" required>
            <input matEndDate formControlName="end" placeholder="End date" required>
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-3" *ngIf="IsAdmin || IsSuperAdmin">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Seleziona risorse</mat-label>
          <mat-select #SelectTeam formControlName="team" multiple>
            <mat-option (click)="toggleAllSelection()"><span *ngIf="!SelTeamSelected">Seleziona tutto</span><span *ngIf="SelTeamSelected">Deseleziona tutto</span></mat-option>
            <mat-option *ngFor="let user of teams" [value]="user.id">{{user.fullName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3" *ngIf="IsAdmin || IsSuperAdmin">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Seleziona progetti</mat-label>
          <mat-select formControlName="project" multiple>
            <mat-option *ngFor="let p of projects" [value]="p.id">{{p.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-1">
        <button class="btn-save w-100 mt-0"  (click)="search(form)" (click)="reportTab = 1">
          <i class="fa fa-search" style="font-size: 1.2rem;"></i>
        </button>
      </div>

      <div class="col-2">
        <button class="btn-save w-100 mt-0" (click)="excel()">
          <i class="fa fa-download me-2" style="font-size: 1.2rem;"></i> Excel
        </button>
      </div>
    </div>

    <div class="row" *ngIf="!usersView">
      <div class="col-8" *ngIf="DataSource && DataSource.activity.length != 0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link tab-report-chart" [ngClass]="{'active' : reportTab == 1}" id="table-tab" (click)="reportTab=1" data-bs-toggle="tab" data-bs-target="#table-tab-user" type="button" role="tab" aria-controls="table-tab" aria-selected="true">Report</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link tab-report-chart" [ngClass]="{'active' : reportTab == 2}" id="chart-tab" (click)="reportTab=2" data-bs-toggle="tab" data-bs-target="#chart-tab-user" type="button" role="tab" aria-controls="chart-tab" aria-selected="false">Grafici</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="tab-content" id="myTabContent">

      <div  class="col-12 scroll-calendar  p-0 tab-pane fade"  [ngClass]="{'show active' : reportTab == 1}" *ngIf="!usersView && DataSource && DataSource.activity.length > 0 && !loading" id="table-tab-user" style="border-radius: 6px;">
        <table  class="w-100 table_week timerep_table position-relative mb-4">
          <tr class="head">
            <td class="sticky_col p-0">
              <table class="p-0" style="border-radius: 0; width: 400px">
                <tr>
                  <td class="empty sticky_col" style="width: 310px"></td>
                  <td class="empty text-center pe-3">totale</td>
                </tr>
              </table>
            </td>
            <td *ngFor="let m of DataSource.date" class="text-center">{{m | date: 'ccc dd/MM':'':'it'}}</td>
          </tr>

          <tr *ngFor="let a of DataSource.activity; index as i" [ngClass]="{'stripped_row' : (i % 2 == 0)}">
            <td class="sticky_col p-0">
              <table class="inner-table p-0" style="border-radius: 0; width: 400px">
                <tr>
                  <td class="body" style="width: 310px" [ngClass]="{'ps-5': !a.father,'bg-blue': a.father }">{{a.project}} <span *ngIf="!a.father">- {{ (a.activity !=null) ? a.activity : "Nessuna attività"}}</span></td>
                  <td class="text-center text-white p-0" style="width: 90px; border-radius: 0" [ngClass]="{'bg-alter': a.father, 'bg-alter-alpha': !a.father }"><span class="fw-bold">{{a.total}}</span></td>
                </tr>
              </table>
            </td>
            <td [attr.colspan]="DataSource.date.length" *ngIf="a.father" class=""></td>
            <td *ngFor="let h of a.hours" class="text-center"><span *ngIf="h > 0" class="fw-bold">{{h}}h</span> <span *ngIf="h==0">-</span></td>
          </tr>
        </table>

      </div>
      <p *ngIf="!usersView && DataSource && DataSource.activity.length == 0 && !loading" class="lead noActivity text-center">Nessuna attività trovata con i filtri selezionati.</p>

      <app-reporting-week-admin *ngIf="usersView" [data]="DataSource" [loading]="loading" (filterProjectFromAdmin)=getfilterProjectFromAdmin($event)></app-reporting-week-admin>


      <div class="col-12 p-0 tab-pane fade" [ngClass]="{'show active' : reportTab == 2}" *ngIf="!usersView" id="chart-tab-user" style="border-radius: 6px;">
        <app-report-chart (filterProject)=getfilterProjectByname($event) [data]="DataSource" *ngIf="DataSource && DataSource.activity.length > 0 && !loading"></app-report-chart>
      </div>
    </div>
  </div>
</div>


<!-- Loader -->
<div *ngIf="loading" class="row m-0 align-items-center">
  <div class="loader-container">
    <span class="loader"></span>
  </div>
</div>
