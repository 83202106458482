export class AuthenticationDto {
  userName: string = "";
  password: string = "";
}

export class User {
  error?: boolean;
  firstName: string = "";
  lastName?: string;
  id?: string;
  token: string = "";
  username?: string;
  role: string = "";
}
