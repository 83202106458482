
<router-outlet></router-outlet>


<div *ngIf="isLogin" class="options pointer" (click)="openoption = !openoption">
  <i class="fa fa-gear text-white"></i>
</div>
<div class="options-menu" *ngIf="openoption">
  <p class="pointer" *ngIf="showReportingDay" (click)="reportingDay()"><i class="fa fa-clock-o me-2"></i>Attività</p>
  <p class="pointer" *ngIf="showReportingWeek" (click)="reportingWeek()"><i class="fa fa-line-chart me-2"></i>Report</p>
  <p class="pointer" (click)="helpMe()"><i class="fa fa-info-circle me-2"></i>Help</p>
  <p class="mb-0 pointer" (click)="logOut()"><i class="fa fa-user me-2"></i>Log out</p>
</div>
