import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AccountService } from './services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'TimeRep.';
  openoption: boolean = false;
  showReportingWeek: boolean = true;
  showReportingDay: boolean = true;
  isLogin: boolean = false;


  constructor(private accountService: AccountService) { }

  ngOnInit()
  {
    this.Current();
    if (location.pathname == "/reporting-week")
      this.showReportingWeek = false;

    if (location.pathname == "/reporting")
      this.showReportingDay = false;
  }

  /*
   * chiamata utile per verificare che l'utente sia
   * ancora loggato in maniera valida, se fallisce ti porta
   * alla login
   */ 
  private Current() {
    if (location.pathname != '/login' && location.pathname != '/') {
      this.isLogin = true;
      this.accountService.ApiCurrent().subscribe(result => {
        if (!result) {
          window.open("/login", "_self")
        }
      }, error => window.open("/login", "_self"));
    }
  }

  /*
  * logout
  * todo: spostarlo in un componente padre
  */
  logOut() {
    localStorage.removeItem("JWT");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    window.open("/", "_self");
  }

  /*
  * link alla documentazione
  */
  helpMe() {
    window.open(environment.guideUrl, "_blank")
  }

  /*
  * link alla vista della settimana
  */
  reportingWeek() {
    window.open("reporting-week", "_self")
  }

  /*
* link alla vista della odierna
*/
  reportingDay() {
    window.open("reporting", "_self")
  }


}

export class RoleGroup
{
  static User: string = "USJKOJER";
  static Admin: string = "ADIOOKIN";
  static SuperAdmin: string = "SUPOIOOKIN";
}
