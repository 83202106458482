<div class="masthead_report">
  <div class="container my-4 position-relative z-2 min-w">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <h2 class="m-0">{{welcome}} {{user}}</h2>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <app-report-history [historyReload]="historyReload" (setDate)="SetDateToView($event)"></app-report-history>
      </div>
      <div class="col-12 mt-4 d-flex justify-content-center">
        <p class="mt-0 mb-0"> <a *ngIf="(datetoview | date:'dd MMMM YYYY') !=(today| date:'dd MMMM YYYY')" href="/reporting" class="btn-main text-white text-decoration-none">Torna ad oggi</a></p>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 pb-2">
        <div class="row align-items-center">
          <div class="col-2">
            <p (click)="SetDateToView(yesterday)" class="pointer"><i class="fa fa-arrow-left me-2"></i> {{yesterday | date: 'dd MMMM':'':'it'}}</p>
          </div>
          <div class="col-8 text-center">
            <p class="lead ms-3"><i class="fa fa-clock-o"></i> Ore inserite: <span class="fw-bold" [ngStyle]="{'color': totalHour < 8 ? 'orange' : ''}">{{totalHour}}</span></p>
          </div>
          <div class="col-2 text-end">
            <p (click)="SetDateToView(tomorrow)" class="pointer">{{tomorrow | date: 'dd MMMM':'':'it'}} <i class="fa fa-arrow-right ms-2"></i></p>
          </div>
        </div>
      </div>
    </div>-->
    <div class="row">
      <div class="col-12 mt-4">
        <app-report-list  (getHistory)="historyReload=$event" [tagList]="tagList" [datetoview]="datetoview" (totalHour)="totalHour = $event"></app-report-list>
      </div>
    </div>
  </div>
</div>

