import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { AuthenticationDto, User } from "../models/authenticationDto";
import { getReportDetailWeek, getReportHistory, getReportParam } from "../models/getReportParam";
import { JsonReply } from "../models/JSONreply";
import { AddReportParam, ReportBaseDto, ReportDto, TagDto } from "../models/reportDto";

@Injectable({
  providedIn: 'root',
})

export class ReportService {
  prefix: string = environment.serverUrl + '/api/Report';
  prefixTag: string = environment.serverUrl + '/api/Tag';

  public token() {
    return new HttpHeaders()
      .set('Authorization', "Bearer " + localStorage.getItem("JWT"));
  }

  constructor(private httpClient: HttpClient) { }

  public TagCompleteList() {
    return this.httpClient.get<JsonReply<[TagDto]>>(this.prefixTag + '/CompleteList', { headers: this.token() });
  }

  public GetDetail(param: getReportParam) {
    return this.httpClient.post<JsonReply<[ReportDto]>>(this.prefix + '/Detail', param, { headers: this.token() });
  }

  public GetHistory(params: getReportHistory) {
    return this.httpClient.post<JsonReply<[ReportBaseDto]>>(this.prefix + '/History', params, { headers: this.token() });
  }

  public AddReport(param: AddReportParam) {
    return this.httpClient.post<JsonReply<[ReportDto]>>(this.prefix + '/Add', param, { headers: this.token() });
  }

  public DeleteReport(params: number){
    return this.httpClient.get<JsonReply<boolean>>(this.prefix + '/Delete?id='+params, { headers: this.token() });
  }

  public UpdateReport(params: ReportDto) {
    return this.httpClient.post<JsonReply<boolean>>(this.prefix + '/Update', params, { headers: this.token() });
  }

  public GetDetailWeek(params: getReportDetailWeek) {
    return this.httpClient.post<JsonReply<any>>(this.prefix + '/DetailWeek', params, { headers: this.token() });
  }

  public GetDetailWeekExcel(params: getReportDetailWeek) {
    return this.httpClient.post<JsonReply<any>>(this.prefix + '/DetailWeekExcel', params, { headers: this.token() });
  }

}
