<div class="row justify-content-around align-items-center">
  <span style="width: auto"  (click)="prevDate()"><i class="fa fa-chevron-left pointer"></i></span>
  <div class="col" *ngFor="let c of calendar; let i = index" [ngClass]="{'d-flex justify-content-center':  i == 3}">
    <div class="pointer" (click)="goToDate(c.dateActivity)">
      <div class="calendar-head d-flex align-items-center justify-content-center" [ngClass]="{'today':  today == c.dateActivityNumber , 'headCenter':  i == 3}">
        <p class="mb-0 text-center" *ngIf="i==3">{{c.dateActivity | date: 'cccc dd MMM':'':'it'}}</p>
        <p class="mb-0 text-center" *ngIf="i!=3">{{c.dateActivity | date: 'ccc dd MMM':'':'it'}}</p>
      </div>
      <div class="calendar-body d-flex align-items-center  justify-content-center"  [ngClass]="{'headCenter':  i == 3, 'grey': (c.dateActivity| date:'cc') == ('6') || (c.dateActivity| date:'cc') == ('0') }">
        <p class="mb-0 text-center fw-bold">{{c.hours == 0 ? '-' : c.hours + 'h'}}</p>
      </div>
    </div>
  </div>
  <span style="width: auto" (click)="nextDate()"><i class="fa fa-chevron-right pointer"></i></span>
</div>
