<div class="masthead_login">
  <div class="row h-100 m-0 justify-content-center align-items-center pe-md-5">
    <div class="col-12 text-center">
      <h3 class="text-white fw-bold">TimeRep<span class="tw fw-bold"></span></h3>

      <div class="card z-2 p-5 card_login mt-4">
        <h2 class="mb-4 fw-bold">Log in</h2>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
          <div class="mb-4">
            <label>Username</label>
            <input ngModel type="email" name="username" placeholder="username" class="form-control" required>
          </div>
          <div class="mb-4">
            <label>Password</label>
            <div class="position-relative">
              <input ngModel [type]="showPw ? 'text' : 'password'" name="password" placeholder="Password" class="form-control" required />
              <i (click)="showPw = !showPw" class="suffix fa" [ngClass]="showPw ? 'fa-eye-slash' : 'fa-eye'" style="font-size: 1.2rem;"></i>
            </div>
          </div>
          <button type="submit" class="btn btn-main w-100">Login</button>
        </form>
        <p *ngIf="error" class="error mt-4 mb-0">username e/o password non corretti</p>
        <p class="mt-4 mb-0">Powered by <a href="https://reputationmanager.it" target="_blank">Reputation Manager</a></p>
      </div>
    </div>


  </div>
</div>
