import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { RoleGroup } from '../../app.component';
import { getReportDetailWeek, getReportHistory } from '../../models/getReportParam';
import { TagDto, TagTypology } from '../../models/reportDto';
import { AccountService } from '../../services/account.service';
import { ReportService } from '../../services/report.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-reporting-week',
  templateUrl: './reporting-week.component.html',
  styleUrls: ['./reporting-week.component.css']
})

export class ReportingWeekComponent implements OnInit {
  DataSource: any = null;
  loading: boolean = true;
  today: Date = new Date();
  dateEnd: Date = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7);
  user: string = "";
  welcome: string = "";
  projects: TagDto[] = [];
  params = new getReportDetailWeek();
  reportTab: number=1;
  form = new FormGroup({
    range: new FormGroup({ start: new FormControl<Date | null>(null), end: new FormControl<Date | null>(null) }),
    team: new FormControl(),
    project: new FormControl()
  });

  @ViewChild('SelectTeam') SelTeam!: MatSelect;
  SelTeamSelected: boolean = false;

  teams: any;
  IsAdmin: boolean = localStorage.getItem("role") == RoleGroup.Admin;
  IsSuperAdmin: boolean = localStorage.getItem("role") == RoleGroup.SuperAdmin;
  usersView: boolean = false;

  constructor(private ReportService: ReportService, private SharedService: SharedService, private AccountService: AccountService) { }

  ngOnInit()
  {
    var welocomeModel = this.SharedService.userDetail();
    this.user = welocomeModel.user;
    this.welcome = welocomeModel.welcome;
    this.getCompleteTagList();

    if (this.IsAdmin || this.IsSuperAdmin)
      this.getTeam();


    this.params.dateActivity = new Date(Date.UTC(this.dateEnd.getFullYear(), this.dateEnd.getMonth(), this.dateEnd.getDate(), 0, 0, 0, 0));
    this.params.dateActivityEnd = new Date(Date.UTC(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0, 0));

    this.form.controls["range"].controls["start"].setValue(this.params.dateActivity)
    this.form.controls["range"].controls["end"].setValue(this.params.dateActivityEnd)

    this.getDetailWeek(this.params);

  }

  getDetailWeek(params: getReportDetailWeek)
  {
    this.loading = true;
    var activities: any[] = [];

    this.ReportService.GetDetailWeek(params).subscribe(resp => {
      if (resp.ok) {
        if (resp.data.length > 1) {
          this.usersView = true;
          this.DataSource = resp.data;
        }
        else {
          this.usersView = false;
          resp.data[0].activity.forEach((a: any) => {
            a.total = a.hours.reduce((a: number, b: number) => a + b, 0);
            activities.push(a)
          })
          this.manageData(activities, resp.data[0].date)
        }

        this.loading = false;
      }
    })
  }


  /*
   * sistemiamo il dataSource per essere visualizzato
   * creo un attivita padre con le ore totale
   * sort delle attivita
   * valorizzo DataSource
   */
  private manageData(activities: any[], date: any[]) {
    var name: string[] = [];
    activities.map(act => {
      name.push(act.project)
    })

    name = [...new Set(name)];

    name.map(pr => {
      var filtered = activities.filter(a => a.project == pr)
      if (filtered.length > 0) {
        var total: number = 0
        filtered.forEach(t => {
          total += t.total
        })
        activities.push({ project: pr, user: null, father: true, total: total, idProject:filtered[0].idProject})
      }
    })

    activities = activities.sort((a: any, b: any) => {
      const nameA = a.project.toLowerCase() + ((a.father) ? "AA" : "ZZ");
      const nameB = b.project.toLowerCase() + ((b.father) ? "AA" : "ZZ");
      if (nameA < nameB) { return -1; }
      if (nameA > nameB) { return 1; }
      return 0;
    })

    this.DataSource =
    {
      date: date,
      activity: activities
    }
  }


  search(ev: FormGroup) {
    var start = ev.value.range.start;
    var end = ev.value.range.end;
    this.params.guids = (ev.value.team) ? ev.value.team : [];
    this.params.projects = (ev.value.project) ? ev.value.project : [];
    this.params.dateActivity = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0));
    this.params.dateActivityEnd = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0, 0));

    if (ev.valid)
      this.getDetailWeek(this.params)
    else
      ev.setErrors({ 'incorrect': true });
  }

  toggleAllSelection() {
    this.SelTeamSelected = !this.SelTeamSelected;

    if (this.SelTeamSelected) {
      this.SelTeam.options.forEach((item: MatOption) => item.select());
    } else {
      this.SelTeam.options.forEach((item: MatOption) => item.deselect());
    }
    this.SelTeam.close();
    this.SelTeam.options.first.deselect();
  }

  /*
  * recuperiamo la lista di tutti i tag
  * hanno 2 categorie Progetti e Attività
  */
  getCompleteTagList(): void {
    this.ReportService.TagCompleteList().subscribe(res => {
      if (res.ok) {
        this.projects = res.data.filter(t => t.typology == TagTypology.Project && t.status == true);
      }
    })
  }

  /*
  * chiamata per creare l'excel
  * downloadBase64File scarica il file
  */
  excel() {
    this.ReportService.GetDetailWeekExcel(this.params).subscribe(resp => {
      var userName = ((this.params.guids && this.params.guids.length == 1) ? this.teams.filter((a: any) => a.id == this.params.guids[0])[0].fullName : "");
      var name = "Report_" + userName + this.params.dateActivity.getDate() + "-" + (this.params.dateActivity.getMonth() + 1) + "_" + this.params.dateActivityEnd?.getDate() + "-" + ((this.params.dateActivityEnd) ? (this.params.dateActivityEnd.getMonth() + 1) : "");
      this.downloadBase64File(resp.data, name)
    })

  }

  private downloadBase64File(contentBase64: string, fileName: string) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }


  getTeam() {
    this.AccountService.GetTeam().subscribe(resp => {
      this.teams = resp;
    })
  }

  getfilterProjectByname(event: any){
    this.params.projects = event
    this.getDetailWeek(this.params)
    this.form.controls.project.setValue(event)
    this.reportTab = 1
  }

  getfilterProjectFromAdmin(event: any){
    this.params.projects = event[0]
    if(event[1]){
      this.params.guids = event[1]

      this.getDetailWeek(this.params)
      this.form.controls.project.setValue(event[0])
      this.form.controls.team.setValue(event[1])
      this.reportTab = 1
    }else{
      this.getDetailWeek(this.params)
      this.form.controls.project.setValue(event[0])
      this.reportTab = 1
    }

  }
}
