import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src';

@Component({
  selector: 'app-report-chart-admin',
  templateUrl: './report-chart-admin.component.html',
  styleUrls: ['./report-chart-admin.component.css']
})
export class ReportChartAdminComponent {
  @Input() data: any;

  fatherProject: any;
  userTotalsHors!: any[];
  activities: any[] = [];
  activitiToChart: any[] = [];
  activitiUsersToChart: any[] = [];
  resultactivitiUsersToChart: { name: string, y: number, guid: number , idProject: any, color: string}[] = [];
  ngOnChanges(){



    this.fatherProject = this.data.activity.filter((res:any) =>{
      return res.father;
    })
    if(this.fatherProject.length == 1){
      /**tiro fuori le attività degl user selezionati */
      this.activities = this.data.activity.filter((res:any) =>{
        return !res.father;
      })

      /**tiro fuori il totale delle ore per ogni user */
      this.userTotalsHors = [];
      this.activities.forEach(item => {
        const existingUser = this.userTotalsHors.find((userTotal:any) => userTotal.user === item.user);

        if (existingUser) {
          existingUser.total += item.total;
        } else {
          this.userTotalsHors.push({ user: item.user, total: item.total, idProject:item.idProject, guid:item.guid });
        }
      });

      this.activitiToChart = this.userTotalsHors.map((res) => {
        return {
          name: res.user,
          y: res.total,
          id: res.idProject,
          guid: res.guid
        };
      });

      this.createChart(this.activitiToChart, 'Distribuzione per risorse')

    }else{

      this.activitiToChart = this.fatherProject.map((res:any) => {
        return {
          name: res.project,
          y: res.total,
          id: res.idProject,
        };

      });




      /**grafico per risorsorse e progetti */
      this.activitiUsersToChart = this.data.activity.filter((res:any) =>{
        return !res.father;
      })

      this.activitiUsersToChart.forEach((oggetto:any) => {
        const foundItem = this.resultactivitiUsersToChart.find((item) => item.name === oggetto.user && item.guid === oggetto.guid && item.idProject === oggetto.idProject);

        if (foundItem) {
          foundItem.y += oggetto.total;
        } else {
          this.resultactivitiUsersToChart.push({ name: oggetto.user, y: oggetto.total, guid: oggetto.guid, idProject: oggetto.idProject, color: ''});
        }
      });


      //this.activitiToChart.sort((a, b) => b.y - a.y);//metto in ordine le y dal piu grnade al piu piccolo
      this.createChart(this.activitiToChart, 'Distribuzione per progetti')
      this.createChartResources(this.activitiToChart,  this.resultactivitiUsersToChart)
    }


  }


  private createChart(data: any, projectName: any): void {
    var sortedData: [] = Object.assign([], data);
    sortedData.sort((a: { y: number; }, b: { y: number; }) => b.y - a.y)

    Highcharts.chart('chartProjects', {

      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 600,
        marginTop: 100,
        marginBottom: 50
      },
      title: {
        text: projectName,
        align: 'center',
        y: 50
    },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}' //point.y per prendere le ore
      },
      accessibility: {
        point: {
            valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} % ({point.y}h)',
            },

        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign:'middle',
        labelFormat: '<b>{name}</b>: {percentage:.1f}%',

      },
    series: [{
      name: 'ore',
      data: sortedData,
      showInLegend: true,
      events: {
        click: (event: any) => {
          var arrayGuid!: any[]
          if(event.point.guid){
            arrayGuid = [event.point.guid]
          }
          var pointid= [[event.point.id],arrayGuid]
          this.sendPointNameToParent(pointid);
        }
      }
    }]

    } as any);

  }

  @Output() filterProject = new EventEmitter<any [][]>();
  sendPointNameToParent(pointid: any[][]) {
    this.filterProject.emit(pointid);
  }


  private createChartResources(dati:any, datisource: any): void {

    const colors: any = Highcharts.getOptions().colors;
    const colorMap = new Map<number, string>();

    /**Imposto il colore delle risorse in modo che sia uguale a quello del proprio progetto */
    datisource.forEach((item: any) => {
      const idprogetto = datisource.find((oggetto:any) => oggetto.idProject === item.idProject)?.idProject;
      if (idprogetto && !colorMap.has(idprogetto)) {
        const index = colorMap.size % colors.length;
        colorMap.set(idprogetto, colors[index]);
      }

      const color = colorMap.get(idprogetto) || '';
      const lessBrightenColor = new Highcharts.Color(color).setOpacity(0.5).get();
      item.color = lessBrightenColor;
    });



    //calcolo la percentuale di ogni risorsa in base al progetto su cui ha lavorato
    var resourceData = [];
    for (var j = 0; j < datisource.length; j++) {
      var resourceId = datisource[j].idProject;
      var matchingProject = dati.find(function(project: any) {
        return project.id === resourceId;
      });

      if (matchingProject) {
        var resourceY = (datisource[j].y / matchingProject.y) * 100;
        resourceData.push({
          name: datisource[j].name,
          y: datisource[j].y,
          percent: resourceY,
          guid: datisource[j].guid,
          idProject: resourceId,
          color: datisource[j].color
        });
      }
    }



    Highcharts.chart('chartResources', {
      chart: {
          type: 'pie',
          height: 600,
          marginTop: 100,
          marginBottom: 50
      },
      title: {
          text: 'Distribuzione per risorse',
          align: 'center',
          y: 50
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} % ({point.y}h)'
          }
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}' //point.y per prendere le ore
      },
      series: [{
          name: 'Ore',
          data: dati,
          size: '80%',
          dataLabels: {
              color: '#ffffff',
              distance: -30
          },
          events: {
            click: (event: any) => {
              var arrayGuid!: any[]
              if(event.point.guid){
                arrayGuid = [event.point.guid]
              }
              var pointid= [[event.point.id],arrayGuid]
              this.sendPointNameToParent(pointid);
            }
          }
      }, {
          name: 'Ore',
          data: resourceData,
          size: '90%',
          innerSize: '65%',
          dataLabels: {
              format: '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percent:.2f}%</span>',
              filter: {
                  property: 'y',
                  operator: '>',
                  value: 0
              },
              style: {
                  fontWeight: 'normal'
              }
          },
          events: {
            click: (event: any) => {
              var arrayGuid!: any[]
              if(event.point.guid){
                arrayGuid = [event.point.guid]
              }
              var pointid= [[event.point.idProject],arrayGuid]
              this.sendPointNameToParent(pointid);
            }
          },
          id: 'resources'
      }],
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 400
              },
              chartOptions: {
                  series: [{
                  }, {
                      id: 'resources',
                      dataLabels: {
                          enabled: false
                      }
                  }]
              }
          }]
      }
    } as any);


  }


}


