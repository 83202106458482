import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reporting-week-admin',
  templateUrl: './reporting-week-admin.component.html',
  styleUrls: ['./reporting-week-admin.component.css']
})

export class ReportingWeekAdminComponent implements OnChanges {

  DataSource: any
  @Input() data: any = null;
  @Input() loading: boolean = false;
  reportTab: number = 1;
  ngOnChanges() {
    var activities: any[] = [];

    this.data.forEach((m: any) => {
      m.activity.forEach((a: any) => {
        a.total = a.hours.reduce((a: number, b: number) => a + b, 0);
        a.user = m.user;
        activities.push(a)
      })
    })

    this.manageData(activities)
  }

  /*
   * sistemiamo il dataSource per essere visualizzato
   * creo un attivita padre con le ore totale
   * sort delle attivita
   * valorizzo DataSource
   */
  private manageData(activities: any[])
  {
    var name: string[] = [];
    activities.map(act => {
      name.push(act.project)
    })

    name = [...new Set(name)];

    name.map(pr => {
      var filtered = activities.filter(a => a.project == pr)
      if (filtered.length > 0) {
        var total: number = 0
        filtered.forEach(t => {
          total += t.total
        })
        activities.push({ project: pr, user: null, father: true, total: total, idProject:filtered[0].idProject, guid: filtered[0].guid })
      }
    })

    activities = activities.sort((a: any, b: any) => {
      const nameA = a.project.toLowerCase() + ((a.father) ? "AA" : "ZZ");
      const nameB = b.project.toLowerCase() + ((b.father) ? "AA" : "ZZ");
      if (nameA < nameB) { return -1; }
      if (nameA > nameB) { return 1; }
      return 0;
    })

    this.DataSource =
    {
      date: this.data[0].date,
      activity: activities
    }

  }

  @Output() filterProjectFromAdmin = new EventEmitter<any [][]>();
  getfilterProjectByname(event: any){
    this.filterProjectFromAdmin.emit(event);
  }

}
