import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SharedService
{
  /*
  * recupero il nome utente da usare
  * setto il saluto in base all'orario
  */
  userDetail() {
    var model = {user: "", welcome: ""}
    var name = localStorage.getItem("username")
    model.user = name ? name : "";

    var now = new Date().getHours();
    model.welcome = now < 13 ? "Buongiorno" : now < 17 ? "Buon pomeriggio" : "Buonasera";

    return model;
  }
}
