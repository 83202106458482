import { Component, EventEmitter, Input, OnChanges, OnInit, Output, } from '@angular/core';
import { getReportParam } from '../../models/getReportParam';
import { ReportDto, TagDto, TagTypology } from '../../models/reportDto';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})

export class ReportListComponent implements OnChanges {
  today: Date = new Date();
  loading: boolean = false;
  reports: ReportDto[] = [];
  historyReload: number = 0
  displayedColumns: string[] = ['project', 'activity', 'note', 'hours','delete'];

  @Input() tagList: TagDto[] = [];
  @Input() datetoview: Date = new Date();
  @Output() totalHour: EventEmitter<number> = new EventEmitter();
  @Output() getHistory: EventEmitter<number> = new EventEmitter();

  constructor(private reportService: ReportService) { }

  ngOnChanges(): void {
    if (this.tagList.length > 0)
      this.getDetailReport()
  }

  /*
  * dopo aver preso tutti i tag
  * andiamo a prendere il dettaglio del report di giornata
  * inserisco la br nell'oggetto reports sotto la proprieta Progetto e Attività
  * emitto il totale delle ore
  */
  getDetailReport(): void {
    this.loading = true;
    var param = new getReportParam();
    param.dateActivity = this.datetoview;

    this.reportService.GetDetail(param)
      .subscribe({
        next: (res) => {
          if (res.ok) {
            res.data.forEach(report => this.tagBR(report));
            var hours = res.data.map(report => report.hours)
            var _totalHour = hours.reduce((a, b) => a + b, 0);
            this.totalHour.emit(_totalHour)
            this.reports = res.data
            this.loading = false;
            this.historyReload++
            this.getHistory.emit(this.historyReload)
          }
        },
        error: (err: any) => err.status == 401 ? window.open("/login", "_self") : console.log(err)
    })
  }

  /*
  * delete Project
  */
  deleteProject(id: number) {
    this.reportService.DeleteReport(id).subscribe(data => {
      if (data.ok)
        this.getDetailReport();
    })
  }

  /*
  * update project (only Hours and Note)
  */
  updateProject(report: ReportDto)
  {
    this.reportService.UpdateReport(report).subscribe(resp => {
      if (resp.ok)
        this.getDetailReport()
    })
  }

  /*
  * inserisco la br nell'oggetto reports sotto la proprieta Progetto e Attività
  * il progetto el'attività sono sempre uno
  */
  private tagBR(report: ReportDto): void {
    if (report.brreportTags && report.brreportTags?.length == 0)
      return;

    var _project;
    var _activities;

    report.brreportTags?.forEach(r => {
      _project = this.tagList.filter(t => t.id == r.tagId && t.typology == TagTypology.Project && t.status == true)[0];
      _activities = this.tagList.filter(t => t.id == r.tagId && t.typology == TagTypology.Activity && t.status == true)[0];

      if (_project)
        report.projectName = _project.name;
      if (_activities) {
        report.activitiesName = _activities.name;

      }
    })
  }

}
