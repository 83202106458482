import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { AuthenticationDto, User } from "../models/authenticationDto";
import { JsonReply } from "../models/JSONreply";

@Injectable({
  providedIn: 'root',
})

export class AccountService {
  prefix: string = environment.serverUrl + '/api/Account';

  public token() {
    return new HttpHeaders()
      .set('Authorization', "Bearer " + localStorage.getItem("JWT"));
  }

  constructor(private httpClient: HttpClient) { }

  public Login(params: AuthenticationDto) {
    return this.httpClient.post<User>(this.prefix + '/Login', params, { responseType: 'json' });
  }

  public ApiCurrent() {
    return this.httpClient.get<boolean>(this.prefix + '/Current', { headers: this.token() });
  }

  public GetTeam() {
    return this.httpClient.get<boolean>(this.prefix + '/Team', { headers: this.token() });
  }

}
