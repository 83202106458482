<table mat-table *ngIf="!loading && reports.length" [dataSource]="reports" class="w-100 timerep_table">
  <!-- project Column -->
  <ng-container matColumnDef="project">
    <th mat-header-cell *matHeaderCellDef style="width: 20%;">Progetto</th>
    <td mat-cell *matCellDef="let element" class="fw-bold"> {{element.projectName}} </td>
  </ng-container>
  <!-- Hours Column -->
  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef style="width: 8%;" class="text-center"> Ore </th>
    <td mat-cell *matCellDef="let element" class="fw-bold text-center">
      <span *ngIf="!element.updateInProgress">{{element.hours}}</span>
      <mat-form-field *ngIf="element.updateInProgress" appearance="outline" class="hours_input">
        <input matInput name="hours" [(ngModel)]="element.hours" type="number" step="0.5" min="0" max="8" placeholder="Ore" />
      </mat-form-field>
    </td>
  </ng-container>
  <!-- Activity Column -->
  <ng-container matColumnDef="activity">
    <th mat-header-cell *matHeaderCellDef style="width: 30.5%;"> Attività </th>
    <td mat-cell *matCellDef="let element">{{element.activitiesName}}</td>
  </ng-container>
  <!-- Note Column -->
  <ng-container matColumnDef="note">
    <th mat-header-cell *matHeaderCellDef> Note </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="!element.updateInProgress">{{element.note}}</span>
      <mat-form-field *ngIf="element.updateInProgress" appearance="outline" class="w-100">
        <input name="note" [(ngModel)]="element.note" matInput type="text" maxlength="500" placeholder="Note" autocomplete="off" />
      </mat-form-field>
    </td>
  </ng-container>

  <!--Delet-->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef style="width: 8%;"> </th>
    <td mat-cell *matCellDef="let element">
      <i *ngIf="!element.updateInProgress" (click)="element.updateInProgress = true" class="fa fa-pencil delete me-3"></i>
      <i *ngIf="!element.updateInProgress" (click)="deleteProject(element.id)" class="fa fa-trash delete"></i>
      <i *ngIf="element.updateInProgress" (click)="updateProject(element)" class="fa fa-save delete"></i>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="loading" class="loader-container">
  <span class="loader"></span>
</div>

<app-report-add [tagList]="tagList" [datetoview]="datetoview" (newAdd)="getDetailReport()"></app-report-add>
