export class TagDto
{
  id!: number;
  name!: string;
  status!: boolean;
  typology!: number;
}

export class ReportDto
{
  id!: number;
  dateInsert!: Date;
  dateActivity!: Date;
  note!: string;
  userId!: string;
  hours!: number;
  brreportTags?: BRreportTags[]

  //solo FE
  projectName?: string;
  activitiesName?: string;
  updateInProgress: boolean = false;
}

export class ReportBaseDto
{
  dateActivity!: Date;
  hours!: number;
  dateActivityNumber?: number = 0;
}

class BRreportTags
{
  id!: number;
  reportId!: number;
  tagId!: number;
}

export enum TagTypology
{
  Project = 1,
  Activity = 2
}

export class AddReportParam
{
  dateInsert: Date = new Date();
  dateActivity!: Date;
  note?: string;
  hours!: number;
  reportBR?: number[];
}
